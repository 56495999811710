/* Styles for album page -- grid of thumbnails */
.section-title {
  margin: 15px 15px 0;
}
.thumbnail {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
}
.thumbnail__image {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  background-color: #232729;
}
.thumbnail__caption {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 3px 5px;
  color: #eeeeec;
  background-color: #000000;
  font-size: 13px;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
.thumbnail:hover {
  border: 2px solid #ffb02f;
}
.thumbnail:hover .thumbnail__image {
  top: -2px;
  left: -2px;
}
.thumbnail:hover .thumbnail__caption {
  opacity: 0.70;
}
