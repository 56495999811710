/* Base styles for Kaleidoscope */
body {
  background-color: #2E3436;
  color: #eeeeec;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  margin: 0;
  width: 100%;
}
a:link {
  color: #ffb02f;
}
a:visited {
  color: #e88000;
}
a:hover {
  color: #ffcc7a;
}
.nav {
  background-color: #232729;
  padding: 8px 15px 10px;
}
.nav__title {
  font-weight: normal;
  font-size: 26px;
  margin: 0;
}
.footer {
  margin: 15px;
  font-size: 80%;
  color: #888a85;
}
