/* Styles for gallery page -- listing of albums */
.gallery {
  display: flex;
  flex-wrap: wrap;
}
.gallery__row {
  width: 220px;
  margin-right: 15px;
  margin-left: 15px;
}
.year {
  padding-bottom: 15px;
}
.year__title {
  font-size: 20px;
  font-weight: normal;
  margin: 20px 0 15px;
}
.album-link {
  margin-bottom: 15px;
}
.album-link__date {
  font-size: 80%;
  margin-left: 5px;
  color: #888a85;
}
